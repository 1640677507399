<template>
    <v-container style="padding: 16px 32px">
        <v-card
                class="mx-auto"
                outlined
        >
            <v-card-title class="headline">Piroliza opon:</v-card-title>

            <v-card-subtitle style="color: #FFFFFF;padding-left: 22px">
                <v-container fluid>
                    <v-row>
                        <v-col cols="12" lg="3" md="12">
                            <v-img class="defaultImageStyle" src="../assets/pyrolysis/Pyrolysis_1.jpg" alt="Opony - materiał do pirolizy" width="auto" contain></v-img>
                        </v-col>
                        <v-col cols="12" lg="5" md="12">
                            <p class="defaultTextStyle">
                                <span style="font-weight: bolder">Piroliza</span>, termin powstały z połączenia dwóch greckich słów πῦρ-ogień i λύσις-rozkład,
                                oznacza rozkład związku chemicznego pod wpływem podwyższonej temperatury bez obecności tlenu lub innego czynnika utleniającego.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" lg="8" md="12">
                            <p class="defaultTextStyle">
                                <span style="font-weight: bolder">Proces pirolizy opon</span><br>
                                Proces rozpoczyna się od umieszczenia zużytych opon w reaktorze, który musi zostać podgrzany do odpowiedniej temperatury.
                                Źródłem ciepła może być dowolny surowiec dostępny w pobliżu instalacji (olej napędowy, gaz ziemny).
                            </p>
                            <p class="defaultTextStyle">
                                Efektem pierwszego etapu jest uzyskanie gazu pirolitycznego, który po przejściu kilku kolejnych etapów obróbki zostaje poddany skraplaniu,
                                w efekcie czego powstają dwa typy produktów pośrednich: oleje ciężkie oraz oleje lżejsze. Zostają one od siebie odseparowane,
                                a ich część służy do podgrzewania komory reaktora w trakcie trwania cyklu.
                            </p>
                            <span class="defaultTextStyle">
                                Końcowymi produktami procesu pirolizy opon są:
                            </span>
                            <ul>
                                <li>Olej popirolityczny (45%)</li>
                                <li>Sadza poprocesowa (35%)</li>
                                <li>Stal pochodząca z elementów konstrukcyjnych opon (5%)</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" lg="4" md="12">
                            <v-img class="defaultImageStyle" src="../assets/pyrolysis/Pyrolysis_2.jpg" alt="Budowa linii do pirolizy" width="auto" contain></v-img>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-subtitle>
        </v-card>
    </v-container>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'pyrolysis',
  props: [],
  data: function () {
    return {
    }
  },
  mounted: function () {
  },
  computed: {
  },
  methods: {
  }
}
</script>
<style scoped>

</style>
